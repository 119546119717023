<template>
  <el-main class="rh-table">
    <h1>Formations post-graduées</h1>
    <app-table
      tablenom="fpostgrads"
      :cols="colonnes"
      :items="fpostgrads"
      :loadstatus="fpostgradsLoadStatus"
      :deletestatus="fpostgradDeleteStatus"
      :formroutes="fpostFormRoutes"
      :actions="fpostActions"
    >
    </app-table>
  </el-main>
</template>
<script>
import AppTable from "@/components/rh/ui/table/AppTable";

export default {
  components: { "app-table": AppTable },
  data() {
    return {
      idMed: "",
      fpostFormRoutes: {
        edit: { route: "fpostgradedit", params: { idfpg: "id" } },
        create: { route: "fpostgradcreate" },
      },
    };
  },
  computed: {
    fpostgradsLoadStatus() {
      // 1 = loading, 2 = succès, 3 = échec du chargement des données
      return this.$store.state.fpg.fpostgradsLoadStatus;
    },
    fpostgradDeleteStatus() {
      // 1 = deleting, 2 = succès, 3 = échec
      return this.$store.state.fpg.fpostgradDeleteStatus;
    },
    fpostgrads() {
      return this.$store.state.fpg.fpostgrads;
    },
    colonnes() {
      return this.$store.state.fpg.colonnes;
    },
    fpostActions() {
      return this.$store.state.fpg.actions;
    },
  },
};
</script>
